<template>
  <form @submit="handleSubmit">
    <div class="flex flex-col gap-2">
      <label for="email" class="text-inherit flex-[0_0_25%] font-semibold mb-0">
        Subscribe to our newsletter
      </label>
      <div class="w-full md:w-auto input-group">
        <input type="email" v-model="email" placeholder="Enter your email" class="w-full  lg:w-[30rem] disabled:pointer-events-none disabled:bg-grey-200/50" required>
        <Button type="submit" theme="primary" :is-loading="false" :disabled="isProcessing || email === ''">Subscribe</Button>
      </div>
      <div class="flex text-xs text-grey-400 mt-1">
        <input type="checkbox" value="1" id="acceptance" required name="acceptance"><label for="acceptance" class="font-normal">I agree to to the terms and conditions...</label>
      </div>
      <p v-if="success" class="bg-secondary/10 px-4 py-2 rounded-lg !text-secondary font-medium text-center text-sm">
        {{success}}
      </p>
      <p v-else-if="error" class="text-red text-sm">
        {{error}}
      </p>
    </div>
  </form>
</template>

<script setup lang="ts">
  import Button from "~/components/global/Buttons/Button.vue";
  import axios from "axios";

  const email = ref('');
  const success = ref(null);
  const error = ref(null);
  const isProcessing = ref(false);

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    try {
      clearMessages();

      isProcessing.value = true;

      const response = await axios.post('/newsletter-signup', {
        email: email.value
      }, {
        headers: {
          'Cache-Control': 'no-store',
        },
        params: {
          timestamp: new Date().getTime()
        }
      });

       if (response?.data?.success !== null) {
         if (response.data.success) {
           success.value = response.data.message;
           email.value = '';
         } else {
           error.value = response.data.message;
         }
       }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          console.log(err.response.data);
          error.value = err.response.data.message;
        }
      }
    } finally {
      isProcessing.value = false;
    }
  }

  const clearMessages = () => {
    if (error.value) {
      error.value = null;
    }
    if (success.value) {
      success.value = null;
    }
  }
</script>
